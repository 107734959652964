export const ActionTypes = {
  SET_USER: "SET-USER",
  REMOVE_USER: "REMOVE_USER",
  ADD_TO_CART: "ADD_TO_CART",
  CLEAR_CART: "CLEAR_CART",
  INCREASE_CART_QUANTITY: "INCREASE_CART_QUANTITY",
  DECREASE_CART_QUANTITY: "DECREASE_CART_QUANTITY",
  DELETE_CART_ITEM: "DELETE_CART_ITEM",
  INCREASE_CART_QUANTITY_IN_CART: "INCREASE_CART_QUANTITY_IN_CART",
};
